.cardImg
{
	position:relative;
	width:100%;
	height:350px;
	border-radius: 10px;
	box-shadow: 0px 0px 5px gray;
	/* border:1px solid #000; */
}
.cardImg img
{
	width:100%;
	height:100%;
	border-radius: 10px;

	/* border:1px solid #000; */
}
.info-box {
	display: block;
	overflow: hidden;
	position: relative;
	border-radius:10px;
	
  }
  .stick{
	position:absolute;
	top:5px;
	left:5px;
	z-index:100;
	text-decoration:none;
	color:#000;
	background:#ffc;
   display:flex;
   justify-content: center;
   align-items: center;
	height:5em;
	width:5em;
	padding:30px 1em;
	clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 10%);
	box-shadow: 5px 5px 7px rgba(33,33,33,.7);
	transform: rotate(-6deg);
	transition: transform .15s linear;
  }
  .stick::after
  {
	  content: url('../images/boardpin-removebg-preview.png');
	  position: absolute;
	  width: 10px;
	  height: 10px;
	
	  left: 2px;
	  top: 2px;
	  z-index:-10;
	  /* border-bottom-right-radius: 5px; */
	  /* box-shadow: 1px 1px 2px 0px #22146E; */
	  transition: 0.5s;
  }
  .advisor-item .info-box  {
	width: 100%; 
    height: 100%;
    /* object-fit: cover; */
    object-fit: cover;
    object-position: center;
    max-height: 170px;
    width: 100%;
  }