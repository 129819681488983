.Tabs-container {
    margin: 60px 0px 50px 0px;
    /* background-color: #eee; */
    border:1px solid #eee;
    overflow-x:auto;
    /* padding: 10px 0px; */
    /* border-radius:50px; */
}
.Tabs-container .swiper-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    
  }
  
.ButtonG {
    border: none;
    color:#000;
    font-weight: 600;
    
    font-size:16px;
    font-family: var(---headFont);
}

.btn:hover{
    color:red !important;
    background-color: #eee !important;
}
@media(max-width:500px)
{
    .Tabs-container {
        margin: 20px 0px 10px 0px;
        background-color: #eee;
        border:1px solid var(---primaryfont);
        padding: 10px 0px;
        border-radius:50px;
    }
}
.swiper1 .active{
	color:#fff;
	border-bottom:2px solid #000;
  }
  /* .swiper-slide
  {
    margin-right:10px;
    width:20px;
  } */