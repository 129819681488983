.errorlogo {
  width: 100%;
  height: 100%;
}
.errorlogo img {
  width: 90%;
  height: 90%;
}

.ErrorDesc {
  padding: 20px 0px;
}
.ErrorDesc h2 {
  font-size: 60px;
  color: #302043;
  margin-bottom:20px;
}
.ErrorDesc span {
  color: #e98c14;
}
.ErrorDesc p {
  color: #808080;
  margin-bottom:20px;
}
.ErrorDesc a {
  padding: 15px 40px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 3;
  color: white;
  background-color: #302043;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: all 0.3s ease;
}

.ErrorDesc a:hover {
  box-shadow: 0px 10px 10px -10px rgba(54, 24, 79, 0.5);
  transform: translateY(5px);
  background: #e98c14;
  color: white;
}
.ErrorRight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:60px;
}
.errorImg{
    width: 100%;
    height: 100%;
}

.errorImg img{
    width: 100%;
    height: 100%;
    max-width: 450px;
    max-height: 450px;
}
@media(max-width:600px){
    .ErrorDesc h2 {
        font-size: 30px;
        color: #302043;
      }
    .ErrorRight {
        display: flex;
        justify-content: center;
        align-items: center;
        margin:30px;
      }
      .ErrorDesc a {
        padding: 7px 20px;
      }
  }