
.headerSection {
    z-index: 100;
  }
  
  .modal {
    padding-right: 0px !important;
    z-index: 1000000;
  }
  
  .headerSocial {
    background: var(---primeColor);
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .headerSocial .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .contactDetails {
    display: flex;
    z-index: 10000000;
  }
  
  @media (max-width: 767px) {
    .headerSocial .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }
  }
  
  .meetingTime {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .headerSocial a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .headerSocial i {
    color: #fff;
    padding: 0 5px;
    font-size: 0.8rem;
  }
  
  .headerSocial span {
    color: #fff;
    font-size: 0.8rem;
    font-family: var(---robotFont);
  }
  
  .navbar {
    padding: 0;
    margin: 0;
    z-index: 1000;
    box-shadow: 0 2px 2px #eee;
  }
  
  .navbar.sticky {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 3px 8px #808080;
  }
  
  .navbar .container-fluid {
    display: flex;
    flex-direction: column;
  }
  
  .navbar>.container-fluid {
    align-items: flex-start;
  }
  
  .navbar .nav-item {
    margin: 0 10px;
  }
  
  .navbar .nav-link {
    font-family: var(---poppinsFont);
    color: var(---primeColor);
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-brand {
    width: 200px;
    height: 90px;
  }
  
  .navbar-brand img {
    width: 100%;
    height: 100%;
  }
  
  .navbar-nav li.nav-item {
    position: relative;
    cursor: pointer;
    border-top: 1px solid #111;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .bottomNavbar {
    padding: 5px 0;
    border-top: 1px solid;
  }

  
  .bottomNavbar ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 1200px;
  }
  
  .bottomNavbar li a {
    font-size: 0.8rem;
    color: #111;
    text-transform: uppercase;
    font-weight: 400;
  }
  
  .accordion-item {
    background: transparent;
  }
  
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px 7px;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: var(---robotoFont);
    color: var(---primeColor);
    text-align: left;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease;
  }
  
  .accordion-button:hover,
  .accordion-button:focus {
    box-shadow: unset;
    color: var(---primeColor);
  }
  
  .accordion-button:not(.collapsed) {
    color: var(---primeColor);
    background-color: unset;
    box-shadow: unset;
  }
  
  .accordion-body {
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
  }
  
  .accordion-body a {
    color: var(---thirdColor);
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: capitalize;
    text-decoration: none;
    padding: 3px 0;
    display: flex;
    align-items: center;
  }
  
  .accordion-body a:hover {
    color: var(---SecondColor);
  }
  
  .accordion-body a span {
    font-size: 1.2rem;
  }
  
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  
  .navbar-socialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
  }
  
  #socialIdItem {
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  @media only screen and (max-width: 1400px) and (min-width: 1000px) {
    #socialIdItem {
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  .navbar-socialLinks label {
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .navbar-socialLinks ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .navbar-socialLinks ul li a {
    margin: 0 7px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-socialLinks ul li i {
    font-size: 1.2rem;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }
  
  .navbar-socialLinks .fa-facebook:hover {
    color: #3b5998;
  }
  
  .navbar-socialLinks .fa-facebook {
    background-color: #3b5998;
  }
  
  .navbar-socialLinks .fa-youtube:hover {
    color: red;
  }
  
  .navbar-socialLinks .fa-twitter:hover {
    color: #4d8fad;
  }
  
  .navbar-socialLinks .fa-whatsapp:hover {
    color: #47c757;
  }
  
  .navbar-socialLinks .fa-instagram:hover {
    color: #d92e7f;
  }
  
  .navbar-socialLinks .fa-linkedin:hover {
    color: #4d8fad;
  }
  

  .navbar.sticky .navbar-socialLinks .fa-facebook {
    background-color: #3b5998;
  }
  
  .navbar-socialLinks .fa-instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%);
    background: -webkit-linear-gradient(45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%);
    background: linear-gradient(45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  }
  
  .navbar.sticky .navbar-socialLinks .fa-instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%);
    background: -webkit-linear-gradient(45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%);
    background: linear-gradient(45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  }
  
  .navbar-socialLinks .fa-whatsapp {
    background-color: #47c757;
  }
  
  .navbar.sticky .navbar-socialLinks .fa-whatsapp {
    background-color: #47c757;
  }
  
  .navbar-socialLinks .fa-linkedin {
    background-color: #4d8fad;
  }
  
  .navbar.sticky .navbar-socialLinks .fa-linkedin {
    background-color: #4d8fad;
  }
  
  .navbar-socialLinks .fa-youtube {
    background-color: red;
  }
  
  .navbar-socialLinks ul li i:hover {
    transform: scale(1.4);
    background: none;
  }
  
  .nav-link {
    position: relative;
  }
  
  .nav-link:after,
  .nav-link:before {
    transition: all 0.3s;
  }
  
  .nav-link.active,
  .nav-link:hover {
    color: #fff;
  }
  
  .nav-link:after,
  .nav-link:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: ".";
    color: transparent;
    background: #fff;
    height: 3px;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  
  /* Keyframes */
  
  @-webkit-keyframes circle {
    0% {
      width: 1px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 1px;
      z-index: -1;
      background: #eee;
      border-radius: 100%;
    }
  
    100% {
      background: #aaa;
      height: 5000%;
      width: 5000%;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 0;
    }
  }
  
  .closeIcon {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 3rem;
    color: red;
    cursor: pointer;
  }
  
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: flex-start;
  }
  
  .navbar>.container-fluid {
    align-items: center;
  }
  
  .navbar-toggler {
    border: 0;
    border-radius: inherit;
    position: absolute;
    right: 0;
    top: 50%;
    transform: -50%;
    padding: 20px;
    position: relative;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
  }
  
  /* Lines of the Toggler */
  
  .toggler-icon {
    width: 30px;
    height: 3px;
    background-color: rgb(251, 208, 11);
    display: block;
    transition: all 0.2s;
  }
  
  /* Adds Space between the lines */
  
  .middle-bar {
    margin: 5px auto;
  }
  
  /* State when navbar is opened (START) */
  
  .navbar-toggler .top-bar {
    transform: rotate(0);
    transform-origin: 10% 10%;
  }
  
  .navbar-toggler .middle-bar {
    opacity: 10;
    filter: alpha(opacity=0);
  }
  
  .navbar .nav-item .nav-link.active:after,
  .navbar .nav-item .nav-link:hover:after {
    width: 0;
  }
  
  .navbar .nav-item {
    text-align: left;
    position: relative;
    padding: 5px 0;
    width: 100%;
  }
  
  .navbar .nav-item:last-child {
    border-bottom: 1px solid #111;
  }
  
  .navbar .nav-item .nav-link {
    font-size: 1.2rem;
    color: var(---primeColor);
    font-family: var(---robotFont);
    font-weight: 400;
  }
  
  .accordion {
    width: 100%;
    min-width: 100%;
  }
  
  .accordion-header {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100%;
  }
  
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
    width: 100%;
    min-width: 100%;
  }
  
  .navbar .nav-link {
    display: unset !important;
  }
  
  .navbar-nav {
    padding: 120px 10px 0 10px;
    margin: 0;
    overflow: hidden;
  }
  
  .enquiryButtonHeader {
    position: absolute;
    left: 20px;
    top: 20px;
    border: 2px solid var(---primeColor);
    color: var(---primeColor);
    border-radius: 20px;
  }
  
  .navbar-collapse {
    position: fixed;
    top: 0px;
    right: -100%;
    padding-bottom: 10px;
    text-align: left;
    width: 380px;
    height: 100%;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(9px);
  }
  
  .navbar-collapse.collapsing {
    right: -75%;
    transition: right 0s ease;
  }
  
  .navbar-collapse.show {
    right: 0;
    transition: right 300ms ease-in-out;
  }
  
  .navbar-toggler.collapsed~.navbar-collapse {
    transition: right 500ms ease-in-out;
  }
  
  .navbar-expand-lg .navbar-toggler {
    display: block;
  }
  
  /* Remove border from toggler */
  
  .navbar-toggler {
    border: 0;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
  }
  
  /* Lines of the Toggler */
  
  .navbar-toggler-icon {
    width: 2rem;
    height: 3px;
    background-color: var(---primeColor);
    display: block;
    transition: all 0.2s;
    position: relative;
    display: block;
    border-radius: 50px;
  }
  
  .navbar.sticky .navbar-toggler-icon {
    background-color: var(---primeColor);
  }
  
  .navbar-toggler-icon:after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 3px;
    border-radius: 50px;
    background-color: var(---SecondColor);
    top: 10px;
    right: 0;
  }
  
  .navbar.sticky .navbar-toggler-icon:after {
    background-color: var(---SecondColor);
  }
  
  .navbar-toggler-icon:before {
    content: "";
    position: absolute;
    width: 3rem;
    height: 3px;
    background-color: var(---thirdColor);
    bottom: 10px;
    right: 0;
    border-radius: 50px;
  }
  
  .navbar.sticky .navbar-toggler-icon:before {
    background-color: var(---thirdColor);
  }
  
  .nav-open .navbar {
    transform: translateX(0);
    background-color: #111;
  }
  
  .nav-open .navbar-toggler-icon {
    transform: rotate(45deg);
  }
  
  .nav-open .navbar-toggler-icon:before {
    opacity: 0;
  }
  
  .nav-open .navbar-toggler-icon:after {
    transform: rotate(90deg) translate(-6px);
  }
  
  .navbar .nav-item .nav-link {
    font-size: 1.2rem;
    color: var(---primeColor);
    font-family: var(---robotFont);
    font-weight: 400;
    padding-left: 20px;
  }
  
  @media (max-width: 767px) {
    #socialIdItem {
      bottom: 20px;
    }
  
    .accordion-button {
      font-size: 1rem;
      margin: 0 10px;
    }
  
    .navbar-collapse {
      width: 280px;
    }
  
    .closeIcon {
      font-size: 2rem;
    }
  
    .navbar-socialLinks {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      right: unset;
      top: unset;
    }
  
    .navbar .nav-item .nav-link {
      font-size: 1rem;
      color: var(---primeColor);
      font-family: var(---robotFont);
      font-weight: 400;
      padding-left: 20px;
    }
  
    .accordion-body a {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 500px) {
    .navbar-brand {
      width: 150px;
      height: 75px;
    }
  
    .navbar-toggler-icon {
      position: relative;
      width: 1.5rem;
      height: 2px;
      background-color: var(---thirdColor);
    }
  
    .navbar-toggler-icon::after {
      content: "";
      position: absolute;
      width: 1rem;
      height: 2px;
      background-color: var(---SecondColor);
      top: 6px;
      right: 0;
    }
  
    .navbar-toggler-icon::before {
      content: "";
      position: absolute;
      width: 2rem;
      height: 2px;
      background-color: var(---primeColor);
      bottom: 6px;
      right: 0;
    }
  
    .navbar-socialLinks {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      right: unset;
      top: unset;
    }
  
    .navbar-socialLinks ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  
    .navbar-socialLinks ul li a {
      margin: 0 7px;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .navbar-socialLinks ul li i {
      font-size: 1rem;
      color: #fff;
      background-color: var(---thirdColor);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  
    .contactDetails {
      display: flex;
      justify-content: space-between;
    }
  
    .headerSocial .fa {
      color: #fff;
      padding: 0 5px;
      font-size: 0.7rem;
    }
  
    .headerSocial span {
      color: #fff;
      font-size: 0.7rem;
      font-family: var(---robotFont);
    }
  }
  

  
/* ======================================= Footer Section ============================== */

ul {
    margin: 0px;
    padding: 0px;
  }
  
  .footer-section {
    background: var(---primeColor);
    position: relative;
  }
  
  .footer-cta {
    border-bottom: 1px solid #373636;
  }
  
  .single-cta i {
    color: var(---SecondColor);
    font-size: 30px;
    float: left;
    margin-top: 8px;
  }
  
  .cta-text {
    padding-left: 48px;
    display: inherit;
  }
  
  .cta-text h4 {
    color: var(---SecondColor);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .cta-text span {
    color: #eee;
    font-size: 15px;
  }
  
  .footer-content {
    position: relative;
    z-index: 2;
  }
  
  .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }
  
  .footer-logo {
    margin-bottom: 30px;
  }
  
  .footer-logo img {
    max-width: 130px;
  }
  
  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #eee;
    line-height: 28px;
  }
  
  .footer-social-icon span {
    color: var(---SecondColor);
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  
  .footer-social-icon a {
    color: var(---SecondColor);
    font-size: 16px;
    margin-right: 15px;
  }
  
  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    color: #fff;
  }
  
  .facebook-bg {
    background: #3b5998;
  }
  
  .twitter-bg {
    background: #55acee;
  }
  
  .google-bg {
    background: #dd4b39;
  }
  .watsapp-bg {
    background: #47c757;
  }
  
  .footer-widget-heading h3 {
    color: var(---SecondColor);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }
  
  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: var(---SecondColor);
  }
  
  .footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
  }
  
  .footer-widget ul li a:hover {
    color: var(---SecondColor);
  }
  
  .footer-widget ul li a {
    color: #eee;
    text-transform: capitalize;
  }
  
  .subscribe-form {
    position: relative;
    overflow: hidden;
  }
  
  .subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #eee;
    border: 1px solid #eee;
    color: #111;
  }
  
  .subscribe-form button {
    position: absolute;
    right: 0;
    background: var(---SecondColor);
    padding: 13px 20px;
    border: 2px solid var(---SecondColor);
    bottom: 10px;
  }
  
  .subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
  }
  
  .copyright-area {
    background: #202020;
    padding: 25px 0;
  }
  
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
  }
  
  .copyright-text p a {
    color: #fff;
  }
  
  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }
  
  .footer-menu li:hover a {
    color: var(---primeColor);
  }
  
  .footer-menu li a {
    font-size: 14px;
    color: #878787;
  }
  
 .bottomNavbar .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #808080 !important;
 }

 .bottomNavbar .MuiTabs-indicator{
  display: none;
 }
 
 .bottomNavbar .MuiBox-root{
  margin: auto;
 }

 .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  box-shadow: unset;
 }