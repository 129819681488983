@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&family=Playfair+Display:wght@500&family=Poppins&family=Raleway:ital@1&display=swap');


:root {
  ---primeColor: rgb(48, 32, 67);
  ---SecondColor: rgb(233, 140, 20);
  ---thirdColor: rgb(55, 103, 106);
  ---whiteColor: #fff;
  ---blackColor: #111;
  ---poppinsFont: "Poppins", sans-serif;
  ---monstserratFont: "Montserrat", sans-serif;
  ---RelewayFont: "Raleway", sans-serif;
  ---lobster: "Lobster", cursive;
  ---robotoFont: "Roboto", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: var(---robotoFont);
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
a,
p {
  font-family: var(---robotoFont);
  text-decoration: none;
  cursor: pointer;
  color: #111;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 0;
}

section .titleSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

section .titleSection h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2em;
  text-transform: uppercase;
  color: var(---primeColor);
  margin-top: 0.25em;
  letter-spacing: 1px;
  position: relative;
  text-align: center;
}

section .titleSection span {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.1em;
  color: var(---SecondColor);
  text-align: center;
}

section .titleSection h2::after {
  content: "";
  border: 2px solid var(---thirdColor);
  display: block;
  max-width: 50px;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: 0.25em;
  background-color: var(---thirdColor);
}

section .titleSection p {
  max-width: 900px;
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 550px) {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 10px;
  }

  section .titleSection h2 {
    color: var(---primeColor);
    font-size: 1.6rem;
    margin-bottom: 20px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    text-transform: capitalize;
    text-align: center;
    text-align: center;
    position: relative;
    padding: 10px 0;
  }

  section .titleSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 40px;
}
}

.css-67l5gl{
  box-shadow: unset !important;
}