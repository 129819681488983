.listBlog img {
  width: 100%;
  height: 110px;
  object-fit: cover;
  object-position: 25% 25%;
}

@media (max-width: 500px) {
}
@media (max-width: 500px) {
  .listBlog h5 {
    font-size: 0.9rem;
  }

  .listBlog p {
    font-size: 0.85rem;
  }
}

.cardBlog img {
    width: 100% !important;
    height: 200px !important;
    object-fit: cover;
    object-position: 25% 25%;
  }

@media (max-width: 1200px) {
   
      
}