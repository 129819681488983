.home {
  overflow: hidden;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url(https://anytimedigitalmarketing.com/wp-content/uploads/2021/01/dm-trends-2020.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.homeTitle h1 {
  font-size: 2.5rem;
  font-family: var(---robotoFont);
  font-weight: 500;
  color: var(---primeColor);
}

.homeTitle p {
  color: var(---thirdColor);
  font-weight: 500;
  font-size: 1rem;
}

.packageButton {
  background-color: var(---SecondColor);
  font-weight: 500;
  border: 2px solid var(---SecondColor);
  color: #fff;
  font-family: var(---robotoFont);
  border-radius: 50px;
  padding: 7px 25px;
  transition: 0.2s ease-in-out;
}

.buttonsList3 .btn {
  color: black;
}

.packageButton:hover {
  background-color: transparent;
  color: var(---SecondColor);
}

@media (max-width: 990px) {
  .home {
    overflow: hidden;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url(../images/tabBanner.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .homeTitle h1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 767px) {
  .home {
    overflow: hidden;
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    background: url(../images/smallBanner.jpeg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .homeTitle h1 {
    padding: 10px 0 0 0;
    font-size: 1.4rem;
  }

  .homeTitle p {
    color: var(---thirdColor);
    font-weight: 500;
    font-size: 0.8rem;
  }
}

/* ================================== About Us ==================================== */

.aboutSection {
  overflow: hidden;
}

.aboutText {
  max-width: 900px;
  margin: auto;
}

.aboutText .firstPara {
  text-align: center;
  font-weight: 500;
  font-family: var(---robotoFont);
  font-size: 1rem;
  color: #444;
}

.aboutText #quoate {
  font-size: 1.4rem;
  transform: scale(1.8);
}

.aboutText .secondPara {
  font-size: 1.1rem;
  color: rgb(100, 50, 172);
  text-align: center;
  position: relative;
}

.aboutButton {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.aboutButton a {
  color: var(---primeColor);
  padding: 10px 20px;
  border-radius: 50px;
  border: 2px solid var(---primeColor);
}


@media (max-width: 550px) {
  .aboutSection {
    background-color: #eee;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
  }

  .aboutImg {
    width: 100%;
    height: auto;
    padding: 50px;
  }

  .aboutImg img {
    width: 100%;
    height: 100%;
    transform: scale(1.5);
  }

  .aboutText .firstPara {
    text-align: justify;
    font-weight: 500;
    font-family: var(---RelewayFont);
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(---blackColor);
  }

  .aboutText #quoate {
    font-size: 1rem;
    transform: scale(1.2);
  }

  .aboutText .secondPara {
    font-size: 1rem;
    color: rgb(100, 50, 172);
    text-align: justify;
    position: relative;
  }

  .aboutButton a {
    color: var(---whiteColor);
    background-color: var(---primeColor);
    padding: 10px 20px;
    border-radius: 5px;
  }
}

/* ======================================== Services Section ============================ */

.serviceSection {
  background: linear-gradient(to right, rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)),
    url(https://cdn.searchenginejournal.com/wp-content/uploads/2020/10/5-fundamental-shifts-that-are-shaping-search-digital-marketing-5f7f23593ac21-1520x800.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.serviceSection p,
.serviceSection h2 {
  color: #fff !important;
}

.swiper_container {
  height: 30rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* padding-bottom: 120px; */
}

@media (max-width: 500px) {
  .swiper-slide {
    padding-bottom: 20px;
  }
}

.swiper-slide img {
  width: 300px;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  color: #fff;
  border: 2px solid #fff;
  font-size: 1.4rem;
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #fff;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  bottom: 1rem;
  margin: auto;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(255, 255, 255));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #fff;
}

.serviceCard {
  position: relative;
}

.serviceCard h4 {
  color: #fff;
  position: absolute;
  bottom: -150px;
  text-align: center;
  font-size: 1rem;
  opacity: 0;
  transition: 0.5s ease-in-out;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-slide-active .serviceCard h4 {
  bottom: -60px;
  transition: 0.5s ease-in-out;
  opacity: 1;
}

.swiper-pagination-bullet {
  background: rgba(255, 255, 255) !important;
}

/* ====================================== Why Us =========================================== */

.whyUsSection {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
}

.single-service {
  margin-top: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.single-service .part-1 {
  padding: 40px 40px 25px;
  border-bottom: 2px solid #fff;
  min-height: 350px;
}

.single-service .part-1 img {
  width: 70px;
  margin-bottom: 20px;
}

.single-service .part-1 .title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.8em;
}

.single-service .part-2 {
  padding: 30px 40px 40px;
}

.single-service .part-2 .description {
  margin-bottom: 22px;
  color: #6f6f71;
  font-size: 14px;
  line-height: 1.8em;
}

.single-service .part-2 a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

.single-service .part-2 a i {
  margin-right: 10px;
  color: #f70037;
}
.whyUsSection .titleSection p{
  text-transform: capitalize;
  font-weight: 600;
}
.whyUsSection .homeWebsite{
  color: #302043;
}
.whyUsSection .homesearch{
  color: #e98c14;
}
.whyUsSection .homesocial-media{
  color: #37676a;
}

@media (max-width: 950px) {
  .single-service .part-1 {
    padding: 40px 40px 25px;
    border-bottom: 2px solid #fff;
    min-height: auto;
  }
}

/* ======================================================== Service Section ====================================== */

.brandSection {
  background: linear-gradient(to right, rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)),
    url(https://cdn.searchenginejournal.com/wp-content/uploads/2020/10/5-fundamental-shifts-that-are-shaping-search-digital-marketing-5f7f23593ac21-1280x720.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 70px 0;
}

.companyAbout {
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.companyAbout .smallText {
  color: #eee;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 400;
}

.companyAbout .title {
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 10px;
  font-family: var(---robotoFont);
}

.companyAbout .discription {
  text-align: center;
  font-weight: 400;
  color: #eee;
  font: 1rem;
  margin-top: 10px;
  max-width: 800px;
}
.enquiryNowButton {
  border: 2px solid #fff;
  margin-top: 50px;
  border-radius: 50px;
  color: #fff;
}

.enquiryNowButton a {
  color: #fff;
}

.enquiryNowButton a:hover {
  color: #fff !important;
}

@media (max-width: 500px) {
  .companyAbout .title {
    font-size: 1.6rem;
    text-align: center;
  }

  .companyAbout .discription {
    font-size: 0.9rem;
  }
}

/* ===================================================== Our Growth ======================================= */

.progress-circle {
  height: 15.625rem;
  position: relative;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.progress-circle svg {
  height: 100%;
  display: block;
  width: 100%;
}

.progress-circle .progressbar-text {
  text-align: center;
  color: var(---primeColor) !important;
  margin-bottom: 0;
  line-height: 1;
}

.progress-line {
  position: relative;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.progress-line .progressbar-text {
  position: absolute;
  width: 100%;
  color: var(---primeColor);
  left: 0;
  top: -1rem;
  padding: 0;
  margin: 0;
}

.progress-line .progressbar-text .value {
  right: 0;
  position: absolute;
}

.progress-line {
  position: relative;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.progress-circle .progressbar-text .value {
  font-weight: 900;
  font-size: 4.199rem;
}

.progress-circle .progressbar-text span {
  font-size: 1rem;
  display: block;
}

.progress-circle .progressbar-text span {
  font-size: 1rem;
  display: block;
}

.progress-circle .progressbar-text span {
  font-size: 1rem;
  display: block;
}

.progress-circle svg .secondPath {
  stroke-dasharray: 307.919, 307.919;
  stroke-dashoffset: 307.9503;
  transition: 5s ease-in-out;
}

.graph {
  stroke-dashoffset: 100;
  transition: 5s ease-in-out;
}

#defaultProgressbar h4 {
  font-size: 1.2rem;
}

#defaultProgressbar p {
  font-size: 0.85rem;
  color: #444;
}

/* ======================================== Projects Section =========================== */

.css-ui0hvk {
  margin: auto;
  box-shadow: 0 0 3px #808080;
  border-radius: 5px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(---SecondColor) !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: var(---SecondColor) !important;
}

.ourPortfolio {
  background-color: #eee;
}

.ourPortfolio .container {
  overflow-x: hidden;
  position: relative;
}

.portfolioCard i {
  font-size: 1.4rem;
  color: #111;
}

.portfolioCardList {
  width: max-content;
  height: 110px;
}

.portfolioCardList img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .portfolioCardList {
    height: 80px;
  }
}

@media (max-width: 900px) {
  .portfolioCardList {
    height: 60px;
  }
}

.portfolioWebsiteList {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
}

.portFolioDetails {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  margin-top: 30px;
  border-radius: 10px;
}

@media (max-width: 500px) {
  .portFolioDetails {
    padding: 20px;
  }
}

/* ================================================ Packages Digital MArketing ===================================== */
.pricing {
  background-color: #fff !important;
}

.packageSection {
  margin: 20px;
  height: auto;
  width: 300px;
  font-family: "Wavehaus";
  font-weight: 600;
  padding: 1.5em;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 19px 38px rgb(255 255 255), 0 15px 12px rgb(146 146 146 / 20%);
  flex-wrap: wrap;
  color: #080808;
}

.list li {
  font-size: 16px;
  line-height: 20px;
  list-style: none;
  border-bottom: 1px solid #f4f4f4;
  padding-inline-start: 0;
  border-width: 1px;
  padding: 10px;
}

.first {
  margin-top: 20px;
  border-top: 1px solid #f4f4f4;
}

.list {
  width: 100%;
  display: contents;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
label {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

.button {
  padding: 10px 30px;
  text-decoration: none;
  font-size: 1.4em;
  margin: 15px 15px;
  border-radius: 50px;
  color: #f4f4f4;
}

.pricing-button {
  color: white;
  background-color: black;
  font-family: "Wavehaus";
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  padding: 15px 40px 15px 40px;
  width: auto;
  height: auto;
  border-radius: 50px;
}

.pricing-button:hover {
  color: white;
  background-color: rgb(32, 32, 32);
}

.toggle-btn span {
  color: #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e2321;
  -webkit-transition: 0.4s;
  transform: translate(0px, 0px);
  transition: 0.6s ease transform, 0.6s box-shadow;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #50bfe6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #50bfe6;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.package-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.pricing-title {
  font-size: 27px;
}

.pricing-head {
  font-family: "Wavehaus";
  font-weight: 900;
  font-size: 25px;
}

.text1 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Wavehaus";
  font-weight: 800;
  font-size: 20px;
}

.text2 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Wavehaus";
  font-weight: 800;
  font-size: 20px;
}

@media (max-width: 500px) {
  .packages {
    margin: 20px 0px;
  }
}

/* ======================================= working Process ==================================== */

.process {
  padding: 120px 0;
  background-color: #fff;
}

.heading {
  text-align: center;
  margin-bottom: 80px;
  margin-top: -7px;
}

.heading p {
  display: inline-block;
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  color: transparent;
  letter-spacing: 2px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(50%, #ff6c1c),
    to(#ff3a33)
  );
  background: -webkit-linear-gradient(right, #ff6c1c 50%, #ff3a33);
  background: -o-linear-gradient(right, #ff6c1c 50%, #ff3a33);
  background: linear-gradient(-90deg, #ff6c1c 50%, #ff3a33);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 22px;
}

.heading h2 {
  font-size: 55px;
  line-height: 65px;
  font-weight: 700;
  color: #1d2860;
  text-transform: capitalize;
  padding-bottom: 22px;
  margin-top: -7px;
  margin-bottom: 0;
  position: relative;
}

.heading h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50px;
  height: 5px;
  border-radius: 3px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(50%, #ff6c1c),
    to(#ff3a33)
  );
  background: -webkit-linear-gradient(right, #ff6c1c 50%, #ff3a33);
  background: -o-linear-gradient(right, #ff6c1c 50%, #ff3a33);
  background: linear-gradient(-90deg, #ff6c1c 50%, #ff3a33);
}

.process .boxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.process .boxes .single-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.process .boxes .single-box .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.process .boxes .single-box .wrap .part-icon {
  text-align: center;
  width: 110px;
  height: 110px;
  line-height: 110px;
  background: #f2f2f9;
  border-radius: 50%;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
}

.process .boxes .single-box .wrap .part-icon img {
  width: 50px;
}

.process .boxes .single-box .wrap .part-txt h3 {
  font-size: 22px;
  line-height: 100%;
  font-weight: 500;
  color: #1d2860;
  margin-top: -5px;
  margin-bottom: -4px;
}

.process .boxes .single-box .arrow {
  margin-left: 60px;
}

.process .boxes .single-box .arrow.rotate {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.process .boxes .single-box.top .wrap .part-icon {
  margin-bottom: 0;
  margin-top: 40px !important;
}

/* ============================================== Enquiry Form ========================================== */
#contactForm input {
  height: 50px;
}

#contactForm input,
#contactForm textarea {
  background-color: #eee;
  color: #111;
}

#contactForm input:focus,
#contactForm textarea:focus {
  border: 2px solid #111;
  box-shadow: unset;
  background-color: #fff;
}

.enquiryButton {
  background-color: var(---primeColor);
  color: #fff;
  padding: 10px 15px;
}

.enquiryButton:hover,
.enquiryButton:focus{
  outline-offset: 3px !important;
  background-color: var(---primeColor);
  color: #fff;
  padding: 10px 15px;
}

.enquiryButton:focus {
  box-shadow: unset;
  border: unset;
}

.enquiryTitle {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: left;
}

.img-vector {
  max-width: 80%;
  height: auto;
}

/* =========================================== Pricing ================================== */

.pricing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto 3em;
}

.pricing-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  text-align: center;
  -webkit-flex: 0 1 330px;
  flex: 0 1 330px;
}

.pricing-action {
  color: inherit;
  border: none;
  background: none;
}

.pricing-action:focus {
  outline: none;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-palden .pricing-item {
  font-family: "Open Sans", sans-serif;
  cursor: default;
  color: #84697c;
  background: #fff;
  box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
  border-radius: 20px 20px 10px 10px;
  margin: 1em;
}

@media screen and (min-width: 66.25em) {
  .pricing-palden .pricing-item {
    margin: 1em -0.5em;
  }

  .pricing-palden .pricing__item--featured {
    margin: 0;
    z-index: 10;
    box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  }
}

.discountPrice {
  color: #eee;
  font-size: 1.2rem;
}

.pricing-palden .pricing-deco {
  border-radius: 10px 10px 0 0;
  background: var(---primeColor);
  padding: 2em 0 7em;
  position: relative;
}

.pricing-palden .pricing-deco-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
}

.pricing-palden .pricing-title {
  position: relative;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.4vw;
  letter-spacing: 0.5vw;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}

@-webkit-keyframes shine {
  0% {
    background-position: 110% 0%, 0 0;
  }

  100% {
    background-position: -10% 0%, 0 0;
  }
}

@keyframes shine {
  0% {
    background-position: 110% 0%, 0 0;
  }

  100% {
    background-position: -10% 0%, 0 0;
  }
}

.gold {
  color: #ac733c;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) or
  (-webkit-background-clip: text) {
  .gold {
    color: transparent;
    background-image: linear-gradient(
        to right,
        transparent 0%,
        transparent 45%,
        white 50%,
        transparent 55%,
        transparent 100%
      ),
      linear-gradient(
        90deg,
        #c78c48,
        #cf9147 9.4%,
        #cf9348 9.4%,
        #b2763e 33.6%,
        #ad743c 35.7%,
        #ac723d 46.9%,
        #b0773d 51.7%,
        #b0793e 52.1%,
        #c4914c 64.6%,
        #c99750 68.5%,
        #ce9e54 73.2%,
        #f4d188
      );
    background-position: 110% 0%, 0 0;
    background-size: 200% auto, auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-animation: shine 2s ease-in-out 2 alternate 2s;
    animation: shine 2s ease-in-out 2 alternate 2s;
  }
}

.gold:before {
  content: attr(data-gold);
  color: #f0d8a9;
  position: absolute;
  z-index: -1;
  top: -0.08vw;
  left: 0px;
  text-shadow: black 0px 0.08vw 12px;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) or
  (-webkit-background-clip: text) {
  .gold:after {
    content: attr(data-gold);
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 48%,
      rgba(98, 16, 0, 0.5) 50%,
      transparent 75%
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
}

.silver {
  color: #c0c0c0 !important;
  animation: shine 2s ease-in-out 2 alternate-reverse 2s;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) or
  (-webkit-background-clip: text) {
  .silver {
    color: transparent;
    background-image: linear-gradient(
        to right,
        transparent 0%,
        transparent 45%,
        white 50%,
        transparent 55%,
        transparent 100%
      ),
      linear-gradient(
        270deg,
        #8c8c8c 1.3%,
        #999 15%,
        #868686 29.6%,
        #828282 29.6%,
        #7d7d7d 31.8%,
        #797979 31.8%,
        #6a6a6a 38.9%,
        #d3d3d3
      );
    background-position: 110% 0%, 0 0;
    background-size: 200% auto, auto;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shine 2s ease-in-out 2 alternate-reverse 2s;
  }
}

.silver:before {
  content: attr(data-silver);
  color: #fff;
  position: absolute;
  z-index: -1;
  top: -0.08vw;
  left: 0px;
  text-shadow: black 0px 0.08vw 12px;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) or
  (-webkit-background-clip: text) {
  .silver:after {
    content: attr(data-silver);
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 48%,
      rgba(17, 17, 17, 0.5) 50%,
      transparent 75%
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
}

.platinum {
  font-size: 5em;
  text-align: center;
  line-height: 0.95em;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(
    0deg,
    #757575 0%,
    #9e9e9e 45%,
    #e8e8e8 70%,
    #9e9e9e 85%,
    #757575 90% 100%
  );
  -webkit-background-clip: text;
}

.pricing-palden .deco-layer {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.pricing-palden .pricing-item:hover .deco-layer--1 {
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
}

.pricing-palden .pricing-item:hover .deco-layer--2 {
  -webkit-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0);
}

.pricing-palden .icon {
  font-size: 2.5em;
}

.pricing-palden .pricing-price {
  font-size: 3.5em;
  font-weight: bold;
  padding: 0;
  color: #fff;
  margin: 0 0 0.25em 0;
  line-height: 0.75;
}

.pricing-palden .pricing-currency {
  font-size: 0.25em;
  vertical-align: top;
}

.pricing-palden .pricing-period {
  font-size: 0.15em;
  padding: 0 0 0 0.5em;
  font-style: italic;
}

.pricing-palden .pricing__sentence {
  font-weight: bold;
  margin: 0 0 1em 0;
  padding: 0 0 0.5em;
}

.pricing-palden .pricing-feature-list {
  list-style: none;
  padding: 10px 7px 0 7px;
  height: 350px;
  overflow-x: hidden;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  -webkit-transition: 3.3s;
  -moz-transition: 3.3s;
  transition: 3.3s;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  transition-timing-function: linear;
}

.pricing-palden .pricing-feature-list {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.pricing-palden .pricing-feature-list::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.pricing-palden .pricing-feature {
  padding: 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pricing-palden .pricing-feature span {
  font-size: 24px;
  color: green;
  margin: 0 5px;
}

.pricing-palden .pricing-action {
  font-weight: bold;
  margin: 1rem 3em 2em 3em;
  padding: 0.7em 2em;
  color: #fff;
  border-radius: 30px;
  background: var(---primeColor);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.pricing-palden .pricing-action:hover,
.pricing-palden .pricing-action:focus {
  background-color: #100a13;
}

.pricing-palden .pricing-item--featured .pricing-deco {
  padding: 5em 0 8.885em 0;
}

.pricing-feature-data {
  position: relative;
  height: 385px;
  overflow: hidden;
}

.viewMorePackage {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 15px #dee2e6;
  z-index: 100000;
  background: #fff;
  cursor: pointer;
  transform: translateX(-50%);
}

.marqueList:host([direction="up"]),
.marqueList:host([direction="down"]) {
  overflow: unset;
  overflow-y: unset;
  white-space: unset;
}

@media (max-width: 500px) {
  .pricing-palden .pricing-title {
    font-size: 1.4rem;
  }

  .pricing-palden .pricing-price {
    font-size: 2.5rem;
  }

  .pricing-palden .pricing-item {
    margin: 20px 0;
  }
}

/* ==============================================testimonal Section================================ */

.testimonialSection {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
      380deg,
      rgb(48, 32, 67, 0.8),
      rgb(55, 103, 106, 0.8)
    ),
    url(https://github.com/SKDM1990/SKDMFolder/blob/main/images/aboutWork.jpg?raw=true);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
}

.testimonialSection .titleSection h2 {
  color: #fff !important;
}

.testimonialSection .titleSection p {
  color: #eee;
}

.testimonialSwiper {
  overflow: hidden;
}

.testimonialCard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px #808080;
  padding: 20px;
}

.qouateIcon {
  color: var(---thirdColor);
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.testimonialText p {
  color: #444;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  font-family: var(---robotFont);
}

.testimonialCard ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.testimonialCard ul li {
  margin: 0 2px;
}

.testimonialCard ul li i {
  font-size: 1.3rem;
  color: gold;
}

.userImg img {
  width: 100%;
  height: 100%;
}

.userDetail {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0 0 0;
  border-radius: 50px;
}

.userImg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin: 0 10px 0 0;
}

.userName h5 {
  color: var(---primeColor);
  font-size: 1.1rem;
  margin: 0;
}

.userName span {
  color: #808080;
  font-family: var(---robotFont);
  font-size: 0.85rem;
  margin: 0;
}

@media (max-width: 990px) {
  .testimonialList {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testimonialSection .titleSection h2 {
    font-size: 2rem;
  }
}

@media (max-width: 500px) {
  .testimonialSection .titleSection h2 {
    font-size: 1.4rem;
  }

  .testimonialSection .titleSection p {
    text-align: center;
    color: #fff;
    font-size: 0.85rem;
  }
}

/* ====================================================== working process ==================================== */

.rotx {
  transform: rotateY(200deg);
  transition: rotate(60deg);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.process-item {
  position: relative;
  display: inline-block;
}

.process-item p {
  font-size: 0.85rem;
}

@media (min-width: 576px) {
  .process-item .process-item-number {
    font-size: 5.584rem;
  }

  .process-item p {
    font-size: 1rem;
  }
}

.process-item .process-item-number {
  position: absolute;
  color: #808080;
  left: 0;
  top: 0;
  -webkit-transform: translate(-127%, -21%);
  -ms-transform: translate(-127%, -21%);
  transform: translate(-127%, -21%);
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 900;
  background-color: #f9f8fd;
  font-size: 2.8rem;
}

.process-item h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 900;
  line-height: 1.2;
  font-size: 1.777rem;
}

#defaultProcess .container {
  position: relative;
  max-width: 1200px;
}

@media only screen and (max-with: 900px) {
  .portfolioCardList {
    height: 60px !important;
  }
}
