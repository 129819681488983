#aboutUsSection {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.aboutImg {
  width: 100%;
  height: 100%;
}

.aboutImg img {
  width: 100%;
  height: 100%;
}

.aboutUsTitle h1 {
  font-size: 7rem;
  color: var(---primeColor);
  text-transform: capitalize;
  position: relative;
}

.aboutUsTitle h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4rem;
  height: 7px;
  background-color: var(---primeColor);
}

@media (max-width: 1200px) {
  .aboutUsTitle h1 {
    font-size: 5rem;
  }
  .aboutUsTitle h1::after {
    height: 5px;
  }
}

@media (max-width: 1200px) {
  .aboutUsTitle h1 {
    font-size: 3rem;
  }
  .aboutUsTitle h1::after {
    height: 4px;
  }
  .aboutUsTitle p {
    font-size: 0.85rem;
  }
}

.aboutUsTitle p {
  color: #444;
  font-weight: 500;
  text-align: justify;
  font-size: 0.9rem;
}

.theFounder {
  background-color: #eee;
}

.founderImage {
  width: 100%;
  height: 100%;
  max-width: 400px;
  /* border-radius: 50px; */
  margin: 20px auto;
}

.founderImage img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
}

.founderText {
  max-width: 700px;
  width: 100%;
  text-align: center;
  margin: auto;
}

.founderText p {
  font-size: 1rem;
  font-weight: 500;
  color: #111;
}

@media (max-width: 500px) {
  .founderText p {
    font-size: 0.9rem;
  }
}

.teamImage img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.dataIcons a {
  margin: 0 15px;
  font-size: 1.4rem;
  color: var(---primeColor);
  position: relative;
  z-index: 1;
  transition: 0.5s ease-in-out;
}

.dataIcons a::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 0;
  height: 0;
  background-color: var(---primeColor);
  border-radius: 50px;
  z-index: -1;
  transition: 0.5s ease-in-out;
}

.dataIcons a:hover {
  color: #fff;
}

.dataIcons a:hover::after {
  width: 50px;
  height: 50px;
}

.MissionVission {
  background-color: aliceblue;
}

.missionVissionCard {
  padding: 10px;
  border: 10px solid #eee;
  border-radius: 20px;
  position: relative;
  margin-top: 250px;
}

.cardImage {
  width: 200px;
  height: 150px;
}

.cardImage img {
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: -300px;
  left: 0;
}

.cardDiscription {
  max-width: 500px;
  margin: auto;
}

.cardDiscription h4 {
  color: var(---primeColor);
  font-weight: 500;
  font-size: 1.5rem;
}

.cardDiscription p {
  font-size: 0.9rem;
}

@media (max-width: 900px) {
  .cardImage img {
    top: -170px;
  }
  .missionVissionCard {
    margin-top: 150px;
  }
}

@media (max-width: 768px) {
  .TeamSection img {
    width: 100%;
    height: 110px;
    object-fit: cover;
  }

  .TeamSection p {
    font-size: 0.85rem;
    margin-bottom: 10px !important;
  }

  .cardImage img {
    top: -200px;
    max-width: 400px;
    margin: auto;
  }
  .missionVissionCard {
    margin-top: 200px;
  }
}

@media (max-width: 350px) {
  .cardImage img {
    top: -100px;
  }
  .missionVissionCard {
    margin-top: 100px;
  }
}
