.aboutCreereSection {
  background-color: #eee;
  padding-top: 100px !important;
}
.facilityBox {
  box-shadow: 0 0 5px #808080;
  border-radius: 5px;
  padding: 15px 15px;
  background-color: #fff;
}

.facilityBox.topActive {
  transform: translateY(-40px);
}

.facilityBox img {
  max-width: 70px;
  margin-bottom: 20px;
}

.facilityBox p {
  margin: 0;
  color: #808080;
  font-size: 0.9rem;
}

.aboutCareer {
  padding: 0 20px;
}

.aboutCareer h2 {
  color: #111;
  font-weight: 500;
  text-transform: capitalize;
  font-family: var(---primeColor);
}

.aboutCareer h2 span {
  color: var(---SecondColor);
}

.aboutCareer p {
  text-align: justify;
  font-size: 1rem;
  color: #444;
}

.aboutCareer button {
  background-color: var(---SecondColor);
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
  border: unset;
  font-size: 1.1rem;
}

/* ============================== Job Vacancy ============================== */

.jobVacancy {
  background-color: #eee;
}

.jobSectionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media(max-width:767px){
  .jobSectionTitle{
    flex-direction: column;
  }
}

/* ============================= Vacancy Section ================================== */

.jobSearchDesign {
  padding: 40px 40px;
  background-color: #fff;
  border-radius: 10px;
}

@media(max-width:767px){
  .jobSearchDesign{
    padding: 30px 10px;
  }
}
.jobSectionTitle h2 {
  color: var(---primeColor);
  font-size: 1.6rem;
}

.searchBoc {
  position: relative;
}

.searchBoc input {
  padding: 10px 60px;
  background-color: #eee;
  border-radius: 50px;
  color: var(---primeColor);
}

.searchBoc input::placeholder {
  color: #111;
}

.searchBoc svg {
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 1.4rem;
  transform: translateY(-50%);
}

.jobFiltering select {
  border-radius: 50px;
  padding: 10px 20px;
}
.vacancyCard {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
.vacancyCard .buttonList
{
  display: flex;
}
.vacancyCard .buttonList button {
  border: 1px solid var(---SecondColor);
  color: var(---SecondColor);
  /* border-radius: 50px; */
  margin-right: 10px;
}

.vacancyCard .buttonList button:last-child{
  border: 1px solid var(---SecondColor);
    /* background-color: var(---SecondColor); */
    color: peru;
}
