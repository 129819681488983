article {
  width: 100%;
}
ul.package {
  display: flex;
  top: 0px;
  z-index: 10;
  padding-bottom: 14px;
}
li.packageDetail {
  list-style: none;
  flex: 1;
}
li.packageDetail:last-child {
  border-right: 1px solid #ddd;
}
button {
  width: 100%;
  border: 1px solid #ddd;
  border-right: 0;
  border-top: 0;
  padding: 10px;
  background: #fff;
  font-size: 14px;
  font-weight: bold;
  height: 60px;
  color: #999;
}
li.active button {
  background: #f5f5f5;
  color: #000;
}
table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}
th {
  background: #f5f5f5;
  display: none;
}
td,
th {
  height: 53px;
}
td,
th {
  border: 1px solid #ddd;
  padding: 10px;
  empty-cells: show;
}
td,
th {
  text-align: left;
}
td + td,
th + th {
  text-align: center;
  display: none;
}
td.active {
  display: table-cell;
}
.bg-purple {
  border-top: 3px solid #a32362;
}
.bg-red {
  border-top: 3px solid #fc4903;
}
.bg-blue {
  border-top: 3px solid #0097cf;
}
.bg-green {
  border-top: 3px solid green;
}
.sep {
  background: #f5f5f5;
  font-weight: bold;
}
h1 {
  font-size: 48px;
  text-align: center;
  margin: 30px 0;
}
.txt-l {
  font-size: 28px;
  font-weight: bold;
}
.txt-2 {
  font-size: 18px;
  margin: 10px 0;
}
.txt-top {
  position: relative;
  top: -9px;
  left: -2px;
}
.tick {
  font-size: 18px;
  color: #2ca01c;
}
.cross svg {
  font-size: 18px;
  color: red;
}
.hide {
  border: 0;
  background: none;
}

@media (min-width: 990px) {
  ul.package {
    display: none;
  }
  td,
  th {
    display: table-cell !important;
  }
  td,
  th {
    width: 330px;
  }
  td + td,
  th + th {
    width: auto;
  }
}
